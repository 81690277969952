import { ApolloQueryResult } from "@apollo/client";
import React from "react";
import { Capabilities, PermissionName } from "../../clients/graphqlTypes";
import {
  AuthUpdateUserMutationVariables,
  MeQuery,
  ViewerShapeFragment,
} from "./UserDataProvider.generated";

export type AppViewerRole = "INSTALLER" | "MAIN" | null;

export const UserDataContext = React.createContext<{
  isLoading: boolean;
  currentUser: ViewerShapeFragment | null;
  appViewerRole: AppViewerRole;
  isOrganisationAdmin: boolean;
  hasCapability(capability: Capabilities): boolean;
  hasOrganisationPermission(permissionName: PermissionName): boolean;
  updateUser: (variables: AuthUpdateUserMutationVariables) => Promise<void>;
  refetchMe: () => Promise<ApolloQueryResult<MeQuery>>;
}>({} as any);
